<template>
  <v-app>
    <snack-bar ref="snackbar"></snack-bar>
    <v-navigation-drawer app permanent>
      <v-list dense>
        <v-list-item class="clinic-logo mb-2">
            <img src="../assets/img/logo-hms3.png" alt="JHC Clinic" />
            <!-- <v-list-item-title>JH Clinic</v-list-item-title> -->
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="menu in menu_items" link class="menu-item" :to="`/${menu.link}`">
          <v-list-item-icon>
            <v-icon>{{menu.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{menu.text}}</v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-main class="main-back">
      <v-app-bar app >
        <v-toolbar-title  >
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" style="background-color: aliceblue;" small icon @click="changeTheme()">
          <v-icon>mdi-bell</v-icon>
          <v-badge color="red" content="" dot>
            <template v-slot:badge>
              <v-icon>mdi-bell</v-icon>
            </template>
          </v-badge>
        </v-btn>
        <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              style="background-color: #F5F5F5;"
              class="text-capitalize font-weight-regular ma-2"
              text
            >
              <icon-base
                width="22"
                height="22"
                class="blue-grey--text text--darken-3 mr-1"
              >
                <icon-user />
              </icon-base>
              <span class="mx-1 blue-grey--text text--darken-3">
                {{ user.name }}
              </span>
              <icon-base
                v-if="attrs['aria-expanded'] === 'true'"
                width="16"
                height="16"
                class="ml-1 blue-grey--text text--darken-2"
              >
                <icon-chevron-up />
              </icon-base>
              <icon-base
                v-else
                width="16"
                height="16"
                class="ml-1 blue-grey--text text--darken-2"
              >
                <icon-chevron-down />
              </icon-base>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item to="profile">
              <v-list-item-content>
                <v-list-item-title>
                  <div class="d-flex">
                    <icon-base width="16" height="16">
                      <icon-logout />
                    </icon-base>
                    <span
                      class="mx-2 blue-grey--text text--darken-2 font-weight-regular"
                      >Profile</span
                    >
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense>
            <v-list-item @click="logout">
              <v-list-item-content>
                <v-list-item-title>
                  <div class="d-flex">
                    <icon-base width="16" height="16">
                      <icon-logout />
                    </icon-base>
                    <span
                      class="mx-2 blue-grey--text text--darken-2 font-weight-regular"
                      >Logout</span
                    >
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      </v-app-bar>

      <v-container>
        <transition :name="transitionName" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import SnackBar from "@/components/SnackBar";
export default {
  name: "AppLayout",
  components: {
    SnackBar,
  },
  data() {
    return {
      transitionName: "",
      
      menu_items: [
        { text: 'Dashboard', link: '/', icon: 'mdi-view-dashboard' },
        { text: 'Patients', link: 'register-patient', icon: 'mdi-account-group' },
        { text: 'Appointments', link: '/', icon: 'mdi-calendar' },
        { text: 'Doctors', link: 'doctors', icon: 'mdi-doctor' },
        { text: 'Referral Doctors', link: '/', icon: 'mdi-account-multiple-plus-outline' },
        { text: 'Labaratory', link: '/', icon: 'mdi-pill' },
        { text: 'Settings', link: '/', icon: 'mdi-cogs' }
      ],
      
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    newPatient() {
      // Logic for adding a new patient
    },
    viewPatient(item) {
      // Logic for viewing patient details
    },
    editPatient(item) {
      // Logic for editing patient details
    },
    deletePatient(item) {
      // Logic for deleting a patient
    },
    changeTheme(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  },
  mounted() {
    this.transitionName = "fade";
  },

  computed: mapGetters({
    user: "auth/user",
  }),
};
</script>

<style scoped>
.clinic-logo img {
  height: 50px;
  margin-right: auto;
  margin-left: auto;
}

.v-app-bar {
  background-color: #f5f5f5;
}

.v-toolbar-title span {
  font-size: 24px;
  color: #333;
}

.v-btn--icon .v-icon {
  color: #333;
}

.v-avatar img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.v-card {
  margin-top: 20px;
  background-color: #ffffff;
}

.v-card-title {
  background-color: #f5f5f5;
}

.v-btn {
  background-color: #1976D2;
  color: #ffffff;
}

.v-text-field {
  margin-bottom: 20px;
}

.v-data-table-header {
  background-color: #f5f5f5;
}

.v-icon {
  cursor: pointer;
}

.v-list-item-content {
  padding: 0 !important;
}

.v-list-item-title {
  font-size: 18px;
  font-weight: bold;
}

.v-pagination {
  justify-content: center;
  margin: 20px 0;
}
.main-back{
  background-color: #EBF5FF;
}
.v-navigation-drawer {
  background-color: #ffffff; /* White background */
  border-right: 1px solid #e0e0e0; /* Light grey border */
}

.clinic-title {
  font-size: 24px; /* Larger font for clinic title */
  font-weight: bold; /* Bold text */
  color: #007bff; /* Blue color */
}

.v-list-item:not(:first-child){
  padding: 10px 20px; /* Spacing for list items */
}

.v-list-item--active {
  background-color: #EBF5FF !important; /* Light blue background for active item */
  border-left: 5px solid #3497F9;
}

.v-list-item__content {
  color: #607d8b; /* Grey color for list items */
  font-weight: 500; /* Medium weight */
}

.v-list-item__icon {
  color: #607d8b; /* Grey color for icons */
}

.v-list-item--active .v-list-item__content,
.v-list-item--active .v-list-item__icon {
  color: #3497F9 !important; /* Blue color for active item text and icons */
}

.v-divider {
  background-color: #e0e0e0; /* Light grey divider */
}

.v-btn--icon .v-icon {
  color: #333; /* Dark grey color for icon buttons */
}

.v-avatar img {
  height: 40px; /* Height of avatar */
  width: 40px; /* Width of avatar */
  border-radius: 50%; /* Rounded avatar */
}

.v-toolbar__title {
  display: flex;
  align-items: center;
  font-size: 18px; /* Font size for toolbar title */
  font-weight: bold; /* Bold font */
  color: #333; /* Dark grey color */
}

.title-text {
  margin-left: 10px; /* Spacing between logo and title text */
}

.v-badge__badge {
  background-color: #ff0000; /* Red badge color */
}
</style>

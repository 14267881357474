import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3497F9',
        secondary: '#EBF5FF',
        accent: '#9783f3',
        error: '#FF6558',
        info: '#9094AF',
        success: '#17c3b2',
        warning: '#E6A23C'
      },
      dark: {
        primary: '#3497F9',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    }
  }
})
